.detail-right-wrap {
  position: relative;
  .expand-collapse-icon {
    position: absolute;
    width: 30px;
    height: 30px;
    content: "";
    top: 10px;
    left: 6px;
    // background: #cccccc;
    cursor: pointer;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 0.25rem;
  }
}
.short-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.full-description {
  overflow: auto;
  text-overflow: unset;
  display: block;
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
}

//Table Styles
// .table-responsive {
//   min-height: 172px;
// }
.table-responsive::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.table-responsive::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: #f2f2f5;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}

table.table-fixed-col > thead > tr > th:first-child,
table.table-fixed-col > tbody > tr > td:first-child {
  position: absolute;
  width: 60px !important;
  background-color: #fff !important;
  text-align: left;
  font-weight: bold;
  // z-index: 1003;
  display: flex;
  align-items: center;
  justify-content: center;
  // flex-direction: column;
  column-gap: 10px;
}

table.table-fixed-col > thead > tr > th:first-child,
table.table-fixed-col > tbody > tr > th:first-child {
  // min-height: 80px !important;
  border-bottom: none;
  // padding-left: 30px !important;
}

table.table-fixed-col > tbody > tr > td:first-child {
  // min-height: 54px !important;
  padding: 0.5rem 0.5rem !important;
  border-bottom: none;
}

table.table-fixed-col > thead > tr > th:nth-child(2),
table.table-fixed-col > tbody > tr > td:nth-child(2) {
  padding-left: 68px !important;
  min-width: 220px;
}

table.table-fixed-col > thead > tr > th:not(:first-child),
table.table-fixed-col > tbody > tr > th:not(:first-child) {
  min-width: 200px;
}
// td.td-plain-link {
//   min-width: 280px !important;
// }

// table.table-fixed-col > tbody > tr > td:nth-child(2) a {
//   color: var(--ct-secondary-text-emphasis) !important;
//   font-weight: bold;

//Detail Status
.status-slider {
  margin: 0 24px 20px 24px;
}
.status-slider .slick-next:before,
.status-slider .slick-prev:before {
  // color: rgba(0, 0, 0, 0.5) !important;
  color: #338cf0 !important;
}
.status-card {
  display: flex !important;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  padding: 6px 10px;
  font-weight: 600;
  border: 1px solid #a9cdfd;
  background: #e7f2ff;
  // border-right: 1px solid #338cf0;
  cursor: pointer;
}
.status-card:hover {
  background: #dbeafd;
}
.status-card-txt {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 160px;
  margin-bottom: 0;
}
.status-card.active {
  background: #338cf0;
  border: 1px solid #338cf0;
  color: #ffffff;
}
.status-card .status-num {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #cccccc;
  border-radius: 50%;
  color: #000000;
}
.status-card.active .status-num {
  background: #ffffff;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #000000;
}

//Phone input
.react-tel-input .form-control {
  width: 100% !important;
}

//Draggable Form fields
.draggable-formfield-wrap {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 6px 10px;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  margin-bottom: 8px;
}
.dragged-formfield-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 10px;
  margin-bottom: 8px;
}
.add-dragged-field-wrap {
  justify-content: center;
  column-gap: 10px;
  padding: 20px 10px;
  border: 1px dashed #dee2e6;
  border-radius: 0.25rem;
}
.drop-section {
  border: 1px dashed rgba(var(--ct-link-color-rgb), var(--ct-link-opacity, 1)) !important;
}

.fs-right-wrap {
  max-height: 536px;
  overflow-y: auto;
}

.fs-right-wrap::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.fs-right-wrap::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: #f2f2f5;
}

.fs-right-wrap::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}

//form-control
.form-control.required {
  border-left: 2px solid red !important;
}

// column filter
.col-filter-name {
  position: relative;
}
.col-filter-name i {
  position: absolute;
  top: 0;
  right: 10px;
}

// .required-select-input,
// .required-select-input:focus,
.required-input,
.required-input:focus {
  border: 1px solid var(--ct-form-invalid-border-color) !important;
  border-radius: 0.25rem !important;
}
.required-fs-input,
.required-fs-input:focus,
.fs-required {
  border-left: 2px solid var(--ct-form-invalid-border-color) !important;
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.system-fs-input,
.system-fs-input:focus {
  border-right: 2px solid
    rgba(var(--ct-link-color-rgb), var(--ct-link-opacity, 1)) !important;
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
// .cust-select {
//   border: 1px solid var(--ct-border-color) !important;
//   border-radius: 0.25rem !important;
// }
// .react-select__control {
//   min-height: 36px !important;
// }
// .react-select__menu {
//   z-index: 1056 !important;
// }
.system-input {
  border-right: 2px solid #3856c0;
}
.auto-card-icon {
  font-size: 80px;
  color: #00c888;
}

.shade-heading {
  color: var(--#{$prefix}primary);
  text-shadow: rgba(var(--#{$prefix}primary-rgb), 0.3) 5px 1px,
    rgba(var(--#{$prefix}primary-rgb), 0.2) 10px 3px;
  font-size: 2.25rem;
  line-height: 3.625rem;
}
.rs-picker-popup {
  z-index: 9999 !important;
}
.error-text {
  color: #f15776;
  margin-top: 0.25rem;
  font-size: 0.75rem;
}

//Role tree structure
.roles-tree-options {
  visibility: hidden;
}
.role-row:hover .roles-tree-options {
  visibility: visible;
}

.tree {
  --spacing: 1.5rem;
  --radius: 10px;
  margin-left: -40px;
}

.tree li {
  display: block;
  position: relative;
  padding-top: 10px;
  padding-left: calc(2 * var(--spacing) - var(--radius) - 2px);
}

.tree ul {
  margin-left: calc(var(--radius) - var(--spacing));
  padding-left: 0;
}

.tree ul li {
  border-left: 2px dashed #ddd;
}

.tree ul li:last-child {
  border-color: transparent;
}

.tree ul li::before {
  content: "";
  display: block;
  position: absolute;
  // top: calc(var(--spacing) / -2);
  top: calc(var(--spacing) / 12);
  left: -1px;
  width: calc(var(--spacing) + 2px);
  height: calc(var(--spacing) + 1px);
  border: dashed #ddd;
  border-width: 0 0 2px 2px;
}

.tree summary {
  display: block;
  cursor: pointer;
  height: 28px;
}

.tree .node-disabled {
  pointer-events: none;
  color: #ddd;
}

.tree .node-disabled:hover .roles-tree-options {
  display: none;
}

.tree summary::marker,
.tree summary::-webkit-details-marker {
  display: none;
}

.tree summary:focus {
  outline: none;
}

.tree summary:focus-visible {
  outline: 1px dotted #000;
}

.tree li::after,
.tree summary::before {
  content: "";
  display: block;
  position: absolute;
  // top: calc(var(--spacing) / 2 - var(--radius));
  top: calc(var(--spacing) / 0.95 - var(--radius));
  left: calc(var(--spacing) - var(--radius) - 2px);
  width: calc(2 * var(--radius));
  height: calc(2 * var(--radius));
  border-radius: 2px;
  background: transparent;
}
.tree summary::before {
  z-index: 1;
  // background: #999999 url("../../images/expand-collapse.svg") 0 0;
  background: #000000 url("../../images/expand-collapse.svg") 0 0;
  filter: invert(100%);
  -webkit-filter: invert(100%);
  border: 1px solid #ddd;
  opacity: 0.4;
}

.tree details[open] > summary::before {
  background-position: calc(-2 * var(--radius)) 0;
}

//Profile
.profile-detail-icn {
  border: 1px solid;
  border-radius: 20px;
  padding: 4px;
  font-size: 12px;
}

.input-group-icon {
  cursor: pointer;
}
.edit-btn {
  background-color: transparent;
  --ct-btn-active-bg: transparent;
}
.edit-btn:hover {
  background-color: transparent;
}

.btn-action-disable {
  cursor: not-allowed !important;
}
.navbar-custom .topbar-menu .nav-link,
.icon-link {
  color: var(--ct-topbar-item-color) !important;
}

.file-drag-drop-wrap {
  padding: 40px;
  text-align: center;
  border: 1px dashed #6a7c96;
  background: #fafafc;
  border-radius: 4px;
}
.drag-drop-input-lable {
  font-size: 14px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  color: var(--ct-secondary-color) !important;
}
.browse-btn {
  background: #eaeafa;
  padding: 4px 16px;
  font-size: 16px;
  font-weight: 400;
  color: #4d4e8d;
  border: none;
  border-radius: 4px;
  font-size: var(--ct-btn-font-size);
}
.disabled {
  pointer-events: none;
  color: #ddd;
}

//New form settings
.list-active {
  background-color: var(--ct-info-bg-subtle) !important;
}
.fs-title-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  background-color: var(--ct-light);
  padding: 4px 0;
  margin-bottom: 20px;
}
.fs-title-txt,
.fs-title-txt:focus {
  background-color: transparent;
  font-weight: 700;
  border: none !important;
}
.crm-cp {
  cursor: pointer;
}
.crm-cdrag {
  cursor: move;
}
.fs-card-right {
  max-height: calc(100vh - 184px);
  overflow-y: auto;
}
.fs-card-right::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.fs-card-right::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: #f2f2f5;
}

.fs-card-right::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}
.fs-unsed-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.itemDD .dragged-formfield-wrap,
.sortable-ghost .sortable-bg,
.sortable-ghost .column-filteritem-bg {
  background-color: var(--ct-info-bg-subtle) !important;
}
.dra

//Select box
.react-select__control {
  box-shadow: none !important;
  border-color: var(--ct-border-color) !important;
}
.required-select-input .react-select__control {
  border-color: var(--ct-form-invalid-border-color) !important;
}

//Modal backdrop
.modal-backdrop.show {
  z-index: 1055 !important;
}

//crop
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.logo-container {
  position: relative;
  height: 100px;
  width: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  cursor: pointer;
  // border: 2px solid #63a7f3;
  border-radius: 50%;
}
.prof-img {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 998;
}
.file-item {
  .progress-bar {
    background-color: #47ad77;
  }
  .progress {
    --ct-progress-height: 6px;
    --ct-progress-font-size: 0px !important;
    --ct-progress-border-radius: none !important;
  }
}
// .click-load {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-60%, -50%);
//   color: #666;
//   text-align: center;
// }
.click-load {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-container img {
  width: 100%;
  height: 100%;
}
.picture-menu-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  visibility: hidden;
}
.profimg-change-icon {
  color: #ffffff;
  font-size: 34px;
}
.prof-img:hover .picture-menu-wrap {
  visibility: visible;
}
.prof-img-menu {
  width: fit-content;
}
.profimg-menu-tooltip .tooltip-inner {
  padding: 0 !important;
}

.table-scrollable tbody {
  display: block;
  height: 500px;
  overflow: auto;
}
.table-scrollable thead,
.table-scrollable tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-scrollable tbody::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.table-scrollable tbody::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: #f2f2f5;
}

.table-scrollable tbody::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}

// Auto Complete suggession
.rta {
  position: relative;
}
.rta__autocomplete {
  width: fit-content;
  box-shadow: 0 0 4px #999999;
  max-height: 200px;
  overflow-y: auto;
  margin-top: -200px;
  margin-left: 0;
  background: #ffffff;
  // position: relative;
  position: absolute;
  z-index: 1111 !important;
  border-radius: 6px;
}
.rta__autocomplete::-webkit-scrollbar,
.rta__textarea::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.rta__autocomplete::-webkit-scrollbar-track,
.rta__textarea::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: #f2f2f5;
}

.rta__autocomplete::-webkit-scrollbar-thumb,
.rta__textarea::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}
.rta__textarea {
  min-height: 200px !important;
}
.rta__list {
  margin: 0;
  padding: 0;
}
.rta__item {
  list-style-type: none;
  padding: 6px 15px;
  border-bottom: var(--ct-border-width) var(--ct-border-style)
    var(--ct-border-color) !important;
}

.mapping-dw {
  min-width: 150px;
  max-width: 200px;
  min-height: 34px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  padding-right: 20px !important;
  background-color: var(--ct-table-bg) !important;
  border: 1px solid var(--ct-border-color) !important;
  border-radius: 0.25rem !important;
}
// .mapping-dw:empty::after {
//   margin-left: 76px;
// }
.mapping-dw::after {
  top: 14px;
  right: 4px;
  position: absolute;
}
.cust-select-options {
  display: flex;
  align-items: center;
  column-gap: 6px;
  position: relative;
}
.cust-select-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
  pointer-events: none;
}
.cust-select-values {
  padding-left: 54px;
  margin-bottom: 0;
}
.selectbox-with-image__menu-list::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.selectbox-with-image__menu-list::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: #f2f2f5;
}

.selectbox-with-image__menu-list::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}

//Timeline style
.timeline-ul {
  margin: 80px 0 0 0;
  padding: 0;
}
.timeline-list {
  list-style-type: none;
  position: relative;
  min-height: 80px;
}
.timeline-date {
  position: absolute;
  top: -60px;
  left: 30px;
  padding: 4px 30px;
  color: #ffffff;
  background: var(--ct-primary);
  border-radius: 4px;
  margin-bottom: 0;
  z-index: 999;
}
.timeline-time {
  position: absolute;
  top: 4px;
  left: 22px;
  margin-bottom: 0;
  font-size: 12px;
}
.timeline-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -4px;
  left: 90px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  // background: var(--ct-light);
  z-index: 999;
  cursor: default !important;
}
.timeline-content {
  padding: 0 20px 20px 140px;
}
.timeline-list::after {
  position: absolute;
  width: 2px;
  background: var(--ct-light-text-emphasis);
  height: 100%;
  content: "";
  top: 0;
  left: 104px;
}
.timeline-list:last-child::after {
  display: none;
}
.timeline-list-date {
  margin-top: 40px;
}
.timeline-list-date::before {
  position: absolute;
  width: 2px;
  background: var(--ct-light-text-emphasis);
  height: 50px;
  content: "";
  top: -50px;
  left: 104px;
}

.text-none{
  color:#fff !important;
}
//Custom Tooltip Style
.cust-tooltip-wrap {
  background: #ffffff;
  border-radius: 4px;
}
.cust-tooltip-txt {
  width: fit-content;
}
.tooltip-user-wrap {
  display: flex;
  align-items: start;
  column-gap: 10px;
}
.tooltip-user-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

//Email Template style
.etemp-searchmodal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #dee2e6;
}
.etemp-searchmodal-body {
  padding: 20px;
}
.etemp-searchmodal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;
  padding: 10px;
  border-top: 1px solid #dee2e6;
}
.list-table-card {
  min-height: 400px;
}
.list-table-spinner {
  position: absolute;
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.target-user-wrap {
  border: 1px solid var(--ct-border-color-translucent);
  border-radius: 0.25rem;
  padding: 10px 0;
}
.padding-zero{
padding:0px !important
}
.react-select__control {
  // border: none !important;
}
.target-dropdown-menu {
  position: fixed !important;
}


  .unlayer-div {
    position: fixed;
    width: 380px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: #ffffff;
    padding: 0px;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  }
  .unlayer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
  }

  .permission-action-menu {
    position: absolute;
    top: 8px;
    right: 8px;
  }