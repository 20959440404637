.lookup-col {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  border-radius: 4px;
}
.lookup-input {
  width: 95%;
}
